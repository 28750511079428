// export default ContactUsModalCustomisation  
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from 'react'
import { Modal, Text, TextInput } from '..'
import { IconButton } from '@material-ui/core'
import { colors } from '../../Themes'
import ClearIcon from '@material-ui/icons/Clear'
import { Axios } from '../../Config'
import { SecondaryOutlinedButton3 } from '../Button/Button'
import { SelectInput } from '../Input'
import { uploadFileModalStyles, selectedStyles } from './UploadFileModal.styles'
import ManualFileUpload from '../ManualFileUpload/ManualFileUpload'
import axios from 'axios'
import toast from 'react-hot-toast'
import { ErrorToast, SuccessToast } from '../Notification/Notification'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import { useAuth } from '../../Global'
import { errorIcon } from '../../Themes/Images'

let SELECT_OPTIONS = [
  { value: 'nps', label: 'NPS', href: 'https://dyl2njn37ytxk.cloudfront.net/web/NPS.csv' },
  { value: 'appointments', label: 'Appointments', href: 'https://dyl2njn37ytxk.cloudfront.net/web/Other.csv' },
  { value: 'sales', label: 'Sales', href: 'https://dyl2njn37ytxk.cloudfront.net/web/Other.csv' },
  { value: 'other', label: 'Other', href: 'https://dyl2njn37ytxk.cloudfront.net/web/Other.csv' },
]

function containsWhitespace(str) {
  return /\s/.test(str)
}

function isCsv(fileName) {
  return fileName?.includes('csv')
}

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

function UploadFileModal({ isVisible, cancel, onSuccess }) {
  const classes = uploadFileModalStyles()
  const [value, setValue] = useState('')
  const [dropdownValues, setDropdownValues] = useState('')
  const [isDisabled, setDisabled] = useState(true)
  const [error, setError] = useState()
  const [isLoading, setLoading] = useState(false)
  const [isloadingError, setIsloadingError] = useState(false)
  const [myFile, setMyFile] = useState(null)
  const [url, setUrl] = useState('')
  const [formDt, setFormDt] = useState()
  const [progress, setProgress] = useState(0)
  const cancelError = true
  const controllerRef = useRef()
  const { userUploadedFileType, fileUploadStatus, fileUploadVerifying, getUpdatedUser } = useAuth()

  const getTheUser = async () => await getUpdatedUser()

  useEffect(() => {
    if (fileUploadVerifying === true) {
      const interval = setInterval(() => {
        getTheUser()
      }, 1000)
      return () => clearInterval(interval)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadVerifying])

  useEffect(() => {
    if (fileUploadStatus === 'SUCCESS') {
      getUpdatedUser()
      onVerificationSuccess()
      getUpdatedUser()
    }

    if (fileUploadStatus === 'ERROR') {
      getUpdatedUser()
      onVerificationError()
      getUpdatedUser()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadStatus])

  useEffect(() => {
    getUpdatedUser()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadVerifying, fileUploadStatus])

  useEffect(() => {
    // const interval = setInterval(() => {
    //   getFiles()
    // }, 3600)

    // return () => clearInterval(interval)
    getUpdatedUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  window.addEventListener('beforeunload', (event) => {
    onRefresh()
  })

  window.addEventListener('unload', (event) => {
    onRefresh()
  })

  const isCsvFile = isCsv(myFile?.type)
  const containsWhitespaceFile = containsWhitespace(myFile?.name)

  SELECT_OPTIONS = SELECT_OPTIONS.filter(ar => !userUploadedFileType.find(rm => (rm.value.toLowerCase() === ar.value.toLowerCase() && ar.label.toLowerCase() === rm.label.toLowerCase() && ar.href === rm.href)))

  const OPTIONS_ARRAY = [...SELECT_OPTIONS]

  useEffect(() => {
    if (
      value.length === 0 || dropdownValues === '' || myFile === null
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [value, dropdownValues, myFile])

  const onVerificationError = async () => {
    await getUpdatedUser()
  }

  const onVerificationSuccess = async () => {
    await getUpdatedUser()
    setValue('')
    setDropdownValues('')
    setMyFile(null)
    cancel()
    onSuccess()
    toast.custom((t) => (
      <SuccessToast t={t} message='File uploaded successfully!' />
    ))
  }

  const onVerification = async () => {
    if (fileUploadStatus === 'ERROR') {
      await getUpdatedUser()
      await onVerificationError()
    }
    if (fileUploadStatus === 'SUCCESS') {
      await getUpdatedUser()
      await onVerificationSuccess()
    }
  }

  const onSubmit = async (category, description) => {
    setLoading(true)
    controllerRef.current = new AbortController()
    try {
      await Axios.post('/prod/user/fileUploads/uploadFile',
        {
          reportContentType: category,
          details: description
        },
        { signal: controllerRef?.current?.signal },
      ).then((response) => console.log(response)
      )

      if (typeof myFile === 'undefined') return

      const formData = new FormData()

      formData.append('file', myFile, 'manual file integration')

      setFormDt(formData)

      const data = await Axios.get(`/prod/user/fileUploads/getPresignedURL?fileName=${myFile?.name}&reportContentType=${dropdownValues?.value?.toUpperCase()}&fileProcessType=initial`,
      )
      setUrl(data?.data?.data?.signedURL)

      await axios({
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          let percent = 0
          percent = Math.floor((loaded * 100) / total)

          if (progress <= 100) {
            setProgress(percent)
          }
        },
        method: 'put',
        // eslint-disable-next-line max-len
        url: data?.data?.data?.signedURL,
        data: myFile,
        signal: controllerRef?.current?.signal
      })
      setProgress(0)
      await getUpdatedUser()
      await onVerification()
      await getUpdatedUser()
      setLoading(false)
      setDisabled(true)
    } catch (error) {
      setError(error)
      setLoading(false)
      setProgress(0)
      if (error?.name === 'CanceledError') {
        toast.custom((t) => (
          <ErrorToast t={t} message='You canceled the upload' />
        ))
      }
    }
  }



  const onCancelWithError = async () => {
    setIsloadingError(true)
    try {
      await Axios.post(`prod/user/feedback?acknowledgeFileUpload=${cancelError}`,
      )
      setValue('')
      setDropdownValues('')
      setMyFile(null)
      setError()
      setDisabled(true)
      setLoading(false)
      controllerRef?.current?.abort()
      cancel()
      await getUpdatedUser()
      setIsloadingError(false)
    } catch (error) {
      setIsloadingError(false)
    }
  }


  const onCancelFileSubmit = async () => {
    try {
      setMyFile(null)
      await Axios.post(`prod/user/feedback?acknowledgeFileUpload=${cancelError}`,
      )
      await getUpdatedUser()
    } catch (error) {
      // setIsloadingError(false)
    }
  }

  const onRefresh = async () => {
    try {
      await Axios.post(`prod/user/feedback?acknowledgeFileUpload=${cancelError}`,
      )
      await getUpdatedUser()
    } catch (error) {
      // setIsloadingError(false)
    }
  }

  const handleChange = (value) => {
    setDropdownValues(value)
  }

  const onCancel = () => {
    setValue('')
    setDropdownValues('')
    setMyFile(null)
    setError()
    setDisabled(true)
    setLoading(false)
    cancel()
  }

  const uploadFile = (
    <div className={classes.ModalWrapper}>
      <div className={classes.subModal}>
        <div className={classes.text}>
          <Text size='h5' color={colors.purple} weight='bold' className={classes.spaceTittle} >Upload</Text>
        </div>
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          style={{ backgroundColor: 'transparent' }}
          classes={{ root: classes.iconButtonClose }}
          onClick={() => onCancel()}
        >
          <ClearIcon classes={{ root: classes.iconButtonClose }} />
        </IconButton>
      </div>
      <div className={classes.border} />
      {fileUploadStatus === 'ERROR' &&
        <div className={classes.errorDiv}>
          <img className={classes.errorICon} src={errorIcon} />
          <Text className={classes.errorText} color='rgba(238, 67, 82, 0.97)' size='footnote'>The file upload was not successful. <a style={{ textDecoration: 'underline', color: 'rgba(238, 67, 82, 0.97)', cursor: 'pointer' }} href='https://dataplayer.zohodesk.eu/portal/en-gb/kb/articles/having-trouble-uploading-your-file-here-s-how-to-fix-it' target='_blank' rel="noreferrer">What to do?</a></Text>
        </div>
      }
      {/* {error?.response?.data?.error?.message} */}
      <div className={classes.subText2}>
        <Text size='footnote' color={colors.primary} weight='normal' className={classes.underTitle} >What data would you like to upload?</Text>
      </div>
      <div className={classes.dropwdownWrapper}>
        <SelectInput
          value={dropdownValues}
          selectCostumStyles={selectedStyles}
          label="Requests"
          placeholder="Select"
          options={OPTIONS_ARRAY}
          onChange={(val) => handleChange(val)}
        />
      </div>
      {dropdownValues?.value === 'other' &&
        <div style={{ display: 'flex', marginTop: 10 }}>
          <Text size='small' color={colors.primary} weight='normal' className={classes.underTitle} >Give us as much details as you can for the report you want to generate using this file. We will review your requirements and contact you.</Text>
        </div>
      }
      {dropdownValues?.value &&
        <div className={dropdownValues?.value === 'other' ? classes.subTextOther : classes.subText}>
          <div>
            <Text size='small' color={colors.primary} weight='normal' className={classes.underTitle} >The data should be in the same format as the file below:</Text>
          </div>
          <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
            <IconButton
              disableFocusRipple
              centerRipple={true}
              disableRipple={true}
              style={{ backgroundColor: 'transparent', padding: 0 }}

            // onClick={() => onCancel()}
            >
              <a style={{ textDecoration: 'none', color: colors.accent }}
                // href={dropdownValues?.href}
                href={dropdownValues?.href} download={dropdownValues?.href} rel="noreferrer"><SystemUpdateAltIcon /></a>


            </IconButton>
            <a style={{ color: colors.accent }} href={dropdownValues?.href} download={dropdownValues?.href} rel="noreferrer">
              <Text size='subnote' color={colors.accent} weight='bold' className={classes.underTitle} >{dropdownValues?.label}</Text>
            </a>
          </div>
        </div>
      }
      <div className={classes.inputForm}>
        <TextInput
          name='reason'
          costumizedInputStyles={classes.input}
          label='What dashboard reports would you like to have for this data?'
          value={value}
          onChange={(e) => setValue(e.target.value)}
          multiline={true}
          rows={6}
        />
      </div>
      <ManualFileUpload fileToUpload={myFile} setFileToUpload={setMyFile} cancelSubmit={onCancelFileSubmit}
        isCsv={isCsvFile} containsWhitespace={containsWhitespaceFile} progress={progress} setProgress={setProgress} isLoading={isLoading || fileUploadVerifying} />
      {/* {error &&
          <div className={classes.errorDiv}>
            <Text className={classes.errorText} color={colors.secondary} size='footnote'>{error?.response?.data?.error?.message}</Text>
          </div>
      } */}
      <div className={classes.border2} />
      <div className={classes.buttonsWrapper}>
        <SecondaryOutlinedButton3
          className={classes.cancelButton}
          onClick={onCancelWithError}
          disabled={isloadingError}
          loading={isloadingError}
          style={{
            backgroundColor: isloadingError ? colors.white : colors.white,
            color: colors.primary,
          }}
        > Cancel
        </SecondaryOutlinedButton3>
        <SecondaryOutlinedButton3
          className={classes.nextButton}
          disabled={isDisabled || isLoading || !isCsvFile || containsWhitespaceFile || fileUploadVerifying || fileUploadStatus === 'ERROR'}
          loading={isLoading || fileUploadVerifying}
          // verifying={fileUploadVerifying}
          style={{
            backgroundColor: isDisabled || isLoading || !isCsvFile || containsWhitespaceFile || fileUploadVerifying || fileUploadStatus === 'ERROR' ? colors.disabled : colors.primary,
            color: colors.white,
          }}
          onClick={
            () => onSubmit(dropdownValues?.value, value)
          }
        >
          Upload
        </SecondaryOutlinedButton3>
      </div>
    </div>
  )


  return (
    <Modal type="noBlur" costumizedPaperStyles={classes.paperCostum} opened={isVisible}>
      {uploadFile}
    </Modal>
  )
}



export default UploadFileModal

